import { Close } from "assets/svg";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
  TTeamMatchAnswer,
  TTeamMatchQuestion,
  TTeamMatchResult,
} from "services/types";
import { useEventStore, useGuestStore } from "store";
import CdtAppPublicApi from "cdt-app-public-api/dist";
import { clarity } from "react-microsoft-clarity";

export default function TeamMatch() {
  const { event, theme } = useEventStore();
  const { questions = [] } = event?.teamMatchGame || {};
  const { guest, teamMatchResult, setTeamMatchResult } = useGuestStore();
  const [activeQuestion, setActiveQuestion] =
    useState<TTeamMatchQuestion | null>(null);
  const [answers, setAnswers] = useState<{
    [key: string]: TTeamMatchAnswer;
  }>(teamMatchResult?.answers || {});
  const [saving, setSaving] = useState(false);
  const teamAScoreRef = useRef<number>(teamMatchResult?.teamAScore || 0);
  const teamBScoreRef = useRef<number>(teamMatchResult?.teamBScore || 0);
  const timerRef = useRef<number>(teamMatchResult?.timeSpentInSeconds || 0);
  const navigate = useNavigate();

  const sortedQuestions = useMemo(() => {
    return [...questions].sort(() => Math.random() - 0.5);
  }, [questions]);

  const totalQuestions = sortedQuestions.length;

  const activeQuestionIndex = useMemo(() => {
    return sortedQuestions.findIndex((q) => q.id === activeQuestion?.id);
  }, [sortedQuestions, activeQuestion]);

  const currentStep = useMemo(() => {
    if (saving) return "saving";
    if (teamMatchResult) return "result";
    if (!activeQuestion) return "intro";

    return "question";
  }, [saving, activeQuestion, teamMatchResult]);

  const saveResult = useCallback(async () => {
    setSaving(true);

    const quizResult: TTeamMatchResult = {
      eventId: event?.id as string,
      eventCode: event?.code as string,
      guestId: guest?.id as string,
      guestName: guest?.name,
      guestPhotoStoragePath: guest?.photoStoragePath,
      questions: sortedQuestions,
      answers,
      teamAScore: teamAScoreRef.current,
      teamBScore: teamBScoreRef.current,
      totalQuestions,
      timeSpentInSeconds: timerRef.current,
      createdAt: new Date().toISOString(),
    };

    const cdtAppClient = new CdtAppPublicApi();
    const teamMatchResultCreated = await cdtAppClient.createTeamMatch(
      quizResult
    );
    setTeamMatchResult(teamMatchResultCreated as TTeamMatchResult);

    setSaving(false);
  }, [
    event?.id,
    event?.code,
    guest?.id,
    guest?.name,
    guest?.photoStoragePath,
    sortedQuestions,
    answers,
    totalQuestions,
    setTeamMatchResult,
  ]);

  const handleOptionClick = useCallback(
    (option: TTeamMatchAnswer) => {
      if (!activeQuestion || !option) return;

      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        [activeQuestion.id]: option,
      }));

      if (option?.team === "teamA") {
        teamAScoreRef.current += 1;
      } else {
        teamBScoreRef.current += 1;
      }

      const nextIndex = activeQuestionIndex + 1;
      const nextQuestion = sortedQuestions[nextIndex];

      setActiveQuestion(nextQuestion);

      if (!nextQuestion) {
        saveResult();
      }

      if (clarity.hasStarted()) {
        clarity.setTag("team_match", "used");
      }
    },
    [activeQuestion, activeQuestionIndex, sortedQuestions, saveResult]
  );

  const onStartClick = useCallback(() => {
    setActiveQuestion(sortedQuestions[0]);
  }, [sortedQuestions]);

  const handleGameClose = useCallback(() => {
    setActiveQuestion(null);
    setAnswers({});
    teamAScoreRef.current = 0;
    teamBScoreRef.current = 0;
    navigate("/options");
  }, [navigate]);

  useEffect(() => {
    if (clarity.hasStarted()) {
      clarity.setTag("team_match", "viewed");
    }
  }, []);

  return (
    <div
      className={`${theme} h-full w-full flex justify-center items-center px-10 py-16`}
    >
      <div className="h-full w-full flex flex-col justify-start items-center">
        {/* Header */}
        <div className="flex w-full items-center justify-between mb-16">
          <h1 className="m-0 p-0 text-center grow">
            <FormattedMessage
              id="title.teamMatch"
              defaultMessage="Chapéu Seletor"
            />{" "}
          </h1>
          {currentStep !== "intro" && (
            <button
              className="flex items-center text-primary"
              onClick={handleGameClose}
            >
              <span className="text-lg">
                <Close width={36} height={36} color="none" outline="#414141" />
              </span>
            </button>
          )}
        </div>

        {currentStep === "intro" && <Intro onStartClick={onStartClick} />}

        {currentStep === "question" && activeQuestion && (
          <>
            {/* Subheader */}
            <div className="mb-8 flex justify-between w-full">
              {/* Question number */}
              <div>
                <span className="text-xl">
                  <span className="font-black text-black">
                    {activeQuestionIndex + 1}
                  </span>
                  /<span className="text-gray-500">{totalQuestions}</span>
                </span>
              </div>
              <Timer timerRef={timerRef} />
            </div>
            <Question
              key={activeQuestion.id}
              question={activeQuestion}
              onOptionClick={handleOptionClick}
            />
          </>
        )}

        {currentStep === "saving" && (
          <p className="text-center">
            <FormattedMessage
              id="message.quiz.saving"
              defaultMessage="Salvando..."
            />
          </p>
        )}

        {currentStep === "result" && (
          <Result
            answers={answers}
            teamAScore={teamAScoreRef.current}
            teamBScore={teamBScoreRef.current}
            totalQuestions={totalQuestions}
          />
        )}
      </div>
    </div>
  );
}

const Intro = ({ onStartClick }: { onStartClick: () => void }) => {
  const { theme, event } = useEventStore();
  const { teamAName = "Time Noiva", teamBName = "Time Noivo" } =
    event?.teamMatchGame || {};

  const onBackHome = useCallback(() => {
    window.location.href = `/?code=${event?.code}`;
  }, [event?.code]);

  return (
    <div className="flex flex-col justify-center items-center gap-16">
      <h2 className="text-2xl font-medium">
        <FormattedMessage
          id="subtitle.teamMatch.intro"
          defaultMessage="Descubra o seu time"
        />
      </h2>
      <p className="text-lg text-gray-500">
        <FormattedMessage
          id="message.teamMatch.intro"
          defaultMessage="Faça o teste e descubra se você faz parte do time {teamA} ou do
        time {teamB}!"
          values={{
            teamA: teamAName,
            teamB: teamBName,
          }}
        />
      </p>
      <div className="w-full flex flex-col justify-center items-center gap-8">
        <button
          onClick={onStartClick}
          className={`w-full px-4 py-3 ${theme.button}`}
        >
          <span className="text-2xl text-center w-full">
            <FormattedMessage
              id="button.teamMatch.start"
              defaultMessage="Iniciar"
            />
          </span>
        </button>
        <button
          className="py-3 px-10 underline text-lg"
          type="button"
          onClick={onBackHome}
        >
          <FormattedMessage
            id="button.recorder.home"
            defaultMessage="Retornar à tela inicial"
          />
        </button>
      </div>
    </div>
  );
};

const Question = ({
  question,
  onOptionClick,
}: {
  question: TTeamMatchQuestion;
  onOptionClick: (option: { team: string; value: string }) => void;
}) => {
  const { theme } = useEventStore();

  const handleOptionClick = (option: { team: string; value: string }) => {
    onOptionClick(option);
  };

  const [optionOne, optionTwo] = useMemo(() => {
    const options = [
      { team: "teamA", value: question.teamA },
      { team: "teamB", value: question.teamB },
    ];

    return options.sort(() => Math.random() - 0.5);
  }, [question]);

  return (
    <>
      {/* Question */}
      <div className={`${theme.card} card w-full p-6 mb-8`}>
        <h2 className="text-xl font-medium text-center">{question.text}</h2>
      </div>

      {/* Options */}
      <div className="gap-4 flex flex-col justify-start items-center w-full">
        <button
          onClick={() => handleOptionClick(optionOne)}
          className={`w-full h-32 px-4 py-3 ${theme.buttonSecondary} flex items-center justify-between`}
        >
          <span className="w-full text-center text-lg no-select">
            {optionOne.value}
          </span>
        </button>
        <Close
          width={40}
          height={40}
          color="rgb(247, 108, 111)"
          outline="rgb(247, 108, 111)"
        />
        <button
          onClick={() => handleOptionClick(optionTwo)}
          className={`w-full h-32 px-4 py-3 ${theme.buttonSecondary} flex items-center justify-between`}
        >
          <span className="w-full text-center text-lg no-select">
            {optionTwo.value}
          </span>
        </button>
      </div>
    </>
  );
};

const Timer = ({ timerRef }: { timerRef: React.MutableRefObject<number> }) => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prev) => {
        const newTime = prev + 1;
        timerRef.current = newTime;
        return newTime;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [timerRef]);

  return (
    <div className="flex items-center justify-center">
      <span className="text-xl">{time}</span>
    </div>
  );
};

const Result = (props: {
  answers: { [key: string]: TTeamMatchAnswer };
  teamAScore: number;
  teamBScore: number;
  totalQuestions: number;
}) => {
  const { answers, teamAScore, teamBScore, totalQuestions } = props;
  const { event } = useEventStore();
  const { teamAName = "Time Noiva", teamBName = "Time Noivo" } =
    event?.teamMatchGame || {};
  const teamAScorePercent = useMemo(
    () => (teamAScore / totalQuestions) * 100,
    [teamAScore, totalQuestions]
  );
  const teamBScorePercent = useMemo(
    () => (teamBScore / totalQuestions) * 100,
    [teamBScore, totalQuestions]
  );

  const winner = teamAScorePercent > teamBScorePercent ? teamAName : teamBName;

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <h2 className="text-2xl font-medium">
        <FormattedMessage
          id="subtitle.teamMatch.result"
          defaultMessage="Resultado"
        />
      </h2>
      <div className="flex flex-col justify-center items-center py-4 gap-4 w-full">
        <h2 className="text-lg">
          <FormattedMessage
            id="message.teamMatch.result"
            defaultMessage="O seu time é <code>{team}</code>!"
            values={{
              team: winner,
              code: (text) => {
                return <span className="font-extrabold underline">{text}</span>;
              },
            }}
          />
        </h2>
        {/* Progress Bar Container */}
        <div className="flex flex-col justify-center items-center gap-1 w-full">
          <div className="flex justify-between items-center gap-2 w-full">
            <span className="text-lg">{teamAName}</span>
            <span className="text-lg">{teamBName}</span>
          </div>
          <div className="w-full h-8 rounded-full overflow-hidden bg-gray-200 relative">
            {/* Bride's Side */}
            <div
              className="h-full bg-rose-400 absolute left-0 top-0 transition-all duration-500 flex items-center justify-start text-white font-medium px-2"
              style={{ width: `${teamAScorePercent}%` }}
            >
              {teamAScorePercent && `${Math.round(teamAScorePercent)}%`}
            </div>
            {/* Groom's Side */}
            <div
              className="h-full bg-blue-400 absolute right-0 top-0 transition-all duration-500 flex items-center justify-end text-white font-medium px-2"
              style={{ width: `${teamBScorePercent}%` }}
            >
              {teamBScorePercent && `${Math.round(teamBScorePercent)}%`}
            </div>
          </div>
        </div>
        {/* Answers Tree */}
        <div className="flex flex-col justify-start items-start w-full gap-2 py-4">
          {Object.values(answers).map((answer, index) => (
            <div
              key={index}
              className="flex justify-start items-center gap-2 w-full"
            >
              <div
                className={`w-8 h-8 rounded-full ${
                  answer.team === "teamA" ? "bg-rose-400" : "bg-blue-400"
                }`}
              />
              <h2 className="text-sm text-left">{answer.value}</h2>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
