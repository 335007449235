import { Close } from "assets/svg";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { TQuizQuestion, TQuizResult } from "services/types";
import { useEventStore, useGuestStore } from "store";
import CdtAppPublicApi from "cdt-app-public-api/dist";
import { clarity } from "react-microsoft-clarity";

export default function Quiz() {
  const { event, theme } = useEventStore();
  const { guest, quizResult, setQuizResult } = useGuestStore();
  const [activeQuestion, setActiveQuestion] = useState<TQuizQuestion | null>(
    null
  );
  const [answers, setAnswers] = useState<{ [key: string]: string }>(
    quizResult?.answers || {}
  );
  const [saving, setSaving] = useState(false);
  const scoreRef = useRef<number>(quizResult?.score || 0);
  const timerRef = useRef<number>(quizResult?.timeSpentInSeconds || 0);
  const navigate = useNavigate();

  const sortedQuestions = useMemo(() => {
    if (!event || !event.quiz) return [];

    return [...event.quiz].sort(() => Math.random() - 0.5);
  }, [event]);

  const totalQuestions = sortedQuestions.length;

  const activeQuestionIndex = useMemo(() => {
    return sortedQuestions.findIndex((q) => q.id === activeQuestion?.id);
  }, [sortedQuestions, activeQuestion]);

  const currentStep = useMemo(() => {
    if (saving) return "saving";
    if (quizResult) return "result";
    if (!activeQuestion) return "intro";

    return "question";
  }, [activeQuestion, quizResult, saving]);

  const saveResult = useCallback(async () => {
    setSaving(true);

    const quizResult: TQuizResult = {
      eventId: event?.id as string,
      eventCode: event?.code as string,
      guestId: guest?.id as string,
      guestName: guest?.name,
      guestPhotoStoragePath: guest?.photoStoragePath,
      quiz: sortedQuestions,
      answers,
      score: scoreRef.current,
      totalQuestions,
      timeSpentInSeconds: timerRef.current,
      createdAt: new Date().toISOString(),
    };

    const cdtAppClient = new CdtAppPublicApi();
    const quizResultCreated = await cdtAppClient.createQuizResult(quizResult);

    setQuizResult(quizResultCreated as TQuizResult);

    setSaving(false);

    if (clarity.hasStarted()) {
      clarity.setTag("quiz", "used");
    }
  }, [event, guest, sortedQuestions, answers, totalQuestions, setQuizResult]);

  const handleOptionClick = useCallback(
    (optionIndex: number) => {
      if (!activeQuestion) return;

      const optionValue = activeQuestion.options[optionIndex];

      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        [activeQuestion.id]: optionValue,
      }));
      scoreRef.current += activeQuestion.correct === optionValue ? 1 : 0;

      const nextQuestionIndex = activeQuestionIndex + 1;
      const nextQuestion = sortedQuestions[nextQuestionIndex];

      setActiveQuestion(nextQuestion);

      if (!nextQuestion) {
        saveResult();
      }
    },
    [activeQuestion, sortedQuestions, activeQuestionIndex, saveResult]
  );

  const onStartClick = useCallback(() => {
    setActiveQuestion(sortedQuestions[0]);
  }, [sortedQuestions]);

  const handleGameClose = useCallback(() => {
    setActiveQuestion(null);
    setAnswers({});
    scoreRef.current = 0;

    navigate("/options");
  }, [navigate]);

  useEffect(() => {
    if (clarity.hasStarted()) {
      clarity.setTag("quiz", "viewed");
    }
  }, []);

  return (
    <div
      className={`${theme} h-full w-full flex justify-center items-center px-10 py-16`}
    >
      <div className="h-full w-full flex flex-col justify-start items-center">
        {/* Header */}
        <div className="flex w-full items-center justify-between mb-16">
          <h1 className="m-0 p-0 grow text-center">
            <FormattedMessage id="title.quiz" defaultMessage="Quiz" />
          </h1>
          {currentStep !== "intro" && (
            <button
              className="flex items-center text-primary"
              onClick={handleGameClose}
            >
              <span className="text-lg">
                <Close width={36} height={36} color="none" outline="#414141" />
              </span>
            </button>
          )}
        </div>

        {currentStep === "intro" && <Intro onStartClick={onStartClick} />}

        {currentStep === "question" && activeQuestion && (
          <>
            {/* Subheader */}
            <div className="mb-8 flex justify-between w-full">
              {/* Question number */}
              <div>
                <span className="text-xl">
                  <span className="font-black text-black">
                    {activeQuestionIndex + 1}
                  </span>
                  /<span className="text-gray-500">{totalQuestions}</span>
                </span>
              </div>
              <Timer timerRef={timerRef} />
            </div>
            <Question
              key={activeQuestion.id}
              question={activeQuestion}
              onOptionClick={handleOptionClick}
            />
          </>
        )}

        {currentStep === "saving" && (
          <p className="text-center">
            <FormattedMessage
              id="message.quiz.saving"
              defaultMessage="Salvando..."
            />
          </p>
        )}

        {currentStep === "result" && (
          <div className="flex flex-col justify-center items-center">
            <h2 className="text-2xl font-medium">
              <FormattedMessage
                id="subtitle.quiz.result"
                defaultMessage="Resultado"
              />
            </h2>
            <div className="py-4 text-center">
              <span className="text-lg">
                <FormattedMessage
                  id="message.quiz.result"
                  defaultMessage={`Você acertou {score} de {totalQuestions} perguntas em {time} segundos`}
                  values={{
                    score: scoreRef.current,
                    totalQuestions: totalQuestions,
                    time: timerRef.current,
                  }}
                />
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const Intro = ({ onStartClick }: { onStartClick: () => void }) => {
  const { theme, event } = useEventStore();

  const onBackHome = useCallback(() => {
    window.location.href = `/?code=${event?.code}`;
  }, [event?.code]);

  return (
    <div className="flex flex-col justify-center items-center gap-16">
      <h2 className="text-2xl font-medium">
        <FormattedMessage
          id="subtitle.quiz.intro"
          defaultMessage="Topa o desafio?"
        />
      </h2>
      <p className="text-lg text-gray-500">
        <FormattedMessage
          id="message.quiz.intro"
          defaultMessage="Entre todos os convidados, prove que vocé é a pessoa que mais conhece o
        casal!"
        />
      </p>
      <div className="w-full flex flex-col justify-center items-center gap-8">
        <button
          onClick={onStartClick}
          className={`w-full px-4 py-3 ${theme.button}`}
        >
          <span className="text-2xl text-center w-full">
            <FormattedMessage id="button.quiz.start" defaultMessage="Iniciar" />
          </span>
        </button>
        <button
          className="py-3 px-10 underline text-lg"
          type="button"
          onClick={onBackHome}
        >
          <FormattedMessage
            id="button.recorder.home"
            defaultMessage="Retornar à tela inicial"
          />
        </button>
      </div>
    </div>
  );
};

type TQuestionProps = {
  question: TQuizQuestion;
  onOptionClick: (optionId: number) => void;
};

const Question = (props: TQuestionProps) => {
  const { question, onOptionClick } = props;
  const { theme } = useEventStore();

  const handleOptionClick = useCallback(
    (optionId: number) => onOptionClick(optionId),
    [onOptionClick]
  );

  return (
    <>
      {/* Question */}
      <div className={`${theme.card} card w-full p-6 mb-8`}>
        <h2 className="text-xl font-medium text-center">{question.text}</h2>
      </div>

      {/* Options */}
      <div className="gap-4 flex flex-col justify-start items-start w-full">
        {question.options.map((option, index) => (
          <button
            key={index}
            onClick={() => handleOptionClick(index)}
            className={`w-full px-4 py-3 ${theme.buttonSecondary} flex items-center justify-between`}
          >
            <span className="w-full text-center text-lg no-select">
              {option}
            </span>
          </button>
        ))}
      </div>
    </>
  );
};

const Timer = ({ timerRef }: { timerRef: React.MutableRefObject<number> }) => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prev) => {
        const newTime = prev + 1;
        timerRef.current = newTime;
        return newTime;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [timerRef]);

  return (
    <div className="flex items-center justify-center">
      <span className="text-xl">{time}</span>
    </div>
  );
};
