import ChangeLanguageButton from "components/theme/ChangeLanguageButton";
import ShareButton from "components/theme/ShareButton";
import { useLogEvent } from "hooks/useLogEvent";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useEventStore, useGuestStore } from "store";
import { shareLink } from "utils/utils";
import bingoBlackIcon from "../assets/images/bingo-black-icon.png";
import bingoRedIcon from "../assets/images/bingo-red-icon.png";
import foodMenuBlackIcon from "../assets/images/food-menu-black-icon.png";
import foodMenuRedIcon from "../assets/images/food-menu-red-icon.png";
import galleryBlackIcon from "../assets/images/gallery-black-icon.png";
import galleryRedIcon from "../assets/images/gallery-red-icon.png";
import photoChallengeBlackIcon from "../assets/images/photo-challenge-black-icon.png";
import photoChallengeRedIcon from "../assets/images/photo-challenge-red-icon.png";
import quizBlackIcon from "../assets/images/quiz-black-icon.png";
import quizRedIcon from "../assets/images/quiz-red-icon.png";
import recorderBlackIcon from "../assets/images/recorder-black-icon.png";
import recorderRedIcon from "../assets/images/recorder-red-icon.png";
import scheduleBlackIcon from "../assets/images/schedule-black-icon.png";
import scheduleRedIcon from "../assets/images/schedule-red-icon.png";
import teamMatchBlackIcon from "../assets/images/team-match-black-icon.png";
import teamMatchRedIcon from "../assets/images/team-match-red-icon.png";
import uploaderBlackIcon from "../assets/images/uploader-black-icon.png";
import uploaderRedIcon from "../assets/images/uploader-red-icon.png";
import LandingPageButton from "components/theme/LandingPageButton";
import { User } from "assets/svg";
import * as SpacesService from "../libs/digitalocean/SpacesService";

const BLACK_ICONS = {
  recorder: recorderBlackIcon,
  uploader: uploaderBlackIcon,
  gallery: galleryBlackIcon,
  bingo: bingoBlackIcon,
  food_menu: foodMenuBlackIcon,
  photo_challenge: photoChallengeBlackIcon,
  quiz: quizBlackIcon,
  schedule: scheduleBlackIcon,
  team_match: teamMatchBlackIcon,
};

const RED_ICONS = {
  recorder: recorderRedIcon,
  uploader: uploaderRedIcon,
  gallery: galleryRedIcon,
  bingo: bingoRedIcon,
  food_menu: foodMenuRedIcon,
  photo_challenge: photoChallengeRedIcon,
  quiz: quizRedIcon,
  schedule: scheduleRedIcon,
  team_match: teamMatchRedIcon,
};

export default function Options() {
  const { event } = useEventStore();

  return (
    <div className="w-full h-full">
      {event?.betaFeatures !== undefined &&
      event?.betaFeatures?.length !== 0 ? (
        <NewOptions />
      ) : (
        <LegacyOptions />
      )}
    </div>
  );
}

const NewOptions = () => {
  const { event, theme, publicUrl } = useEventStore();
  const { guest } = useGuestStore();
  const { logShareButton } = useLogEvent();
  const intl = useIntl();
  const navigate = useNavigate();
  const [guestPhotoUrl, setGuestPhotoUrl] = useState<string | null>(null);

  const menuIcons = useMemo(
    () => (theme.name === "rose" ? RED_ICONS : BLACK_ICONS),
    [theme]
  );

  const bingoGameEnabled = useMemo(
    () => event?.betaFeatures?.includes("bingo"),
    [event]
  );

  const photoChallengeGameEnabled = useMemo(
    () => event?.betaFeatures?.includes("photo_challenge"),
    [event]
  );

  const quizGameEnabled = useMemo(
    () => event?.betaFeatures?.includes("quiz"),
    [event]
  );

  const teamMatchEnabled = useMemo(
    () => event?.betaFeatures?.includes("team_match"),
    [event]
  );

  const foodMenuEnabled = useMemo(
    () =>
      event?.betaFeatures?.includes("uploads_in_menu") &&
      event?.menuUploads?.find((menu) => menu.id === "food_menu"),
    [event]
  );

  const scheduleEnabled = useMemo(
    () =>
      event?.betaFeatures?.includes("uploads_in_menu") &&
      event?.menuUploads?.find((menu) => menu.id === "schedule"),
    [event]
  );

  const handleRecordClick = useCallback(
    () => navigate("/record/guide"),
    [navigate]
  );

  const handleUploadClick = useCallback(() => navigate("/upload"), [navigate]);

  const handleGalleryClick = useCallback(() => {
    const eventGalleryLink = `https://app.capsuladotempo.com/public/${event?.code}/gallery`;
    window.open(eventGalleryLink, "_blank");
  }, [event]);

  const handleBingoGameClick = useCallback(
    () => navigate("/games/bingo"),
    [navigate]
  );

  const handlePhotoChallengeGameClick = useCallback(
    () => navigate("/games/photo-challenge"),
    [navigate]
  );

  const handleQuizGameClick = useCallback(
    () => navigate("/games/quiz"),
    [navigate]
  );

  const handleTeamMatchClick = useCallback(
    () => navigate("/games/team-match"),
    [navigate]
  );

  const handleFoodMenuClick = useCallback(async () => {
    const uploadItem = event?.menuUploads?.find(
      (menu) => menu.id === "food_menu"
    );

    if (!uploadItem) return;

    const url = await SpacesService.getFileUrl(
      uploadItem.storagePath as string
    );

    window.open(url, "_blank");
  }, [event]);

  const handleScheduleClick = useCallback(async () => {
    const uploadItem = event?.menuUploads?.find(
      (menu) => menu.id === "schedule"
    );

    if (!uploadItem) return;

    const url = await SpacesService.getFileUrl(
      uploadItem.storagePath as string
    );

    window.open(url, "_blank");
  }, [event]);

  const onShareEventLink = useCallback(() => {
    const text = intl.formatMessage({
      id: "message.shareLink",
      defaultMessage: "Compartilhe seus momentos favoritos da festa!",
    });
    shareLink(publicUrl, text, logShareButton);
  }, [intl, publicUrl, logShareButton]);

  const onLandingPageClick = useCallback(() => {
    const capsulaLadingPage = "https://videorecados.capsuladotempo.com";
    window.open(capsulaLadingPage, "_blank");
  }, []);

  useEffect(() => {
    const fetchGuestPhoto = async () => {
      const url = await SpacesService.getFileUrl(
        guest?.photoStoragePath as string
      );
      setGuestPhotoUrl(url);
    };

    if (guest?.photoStoragePath) {
      fetchGuestPhoto();
    }
  }, [guest]);

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center gap-6 bg-white py-4 px-4 shadow-sm">
        {guestPhotoUrl ? (
          <img
            src={guestPhotoUrl}
            alt={guest?.name}
            className="w-16 h-16 rounded-full shadow-md object-cover border-2 border-theme-primary/20"
          />
        ) : (
          <div className="w-16 h-16 rounded-full shadow-md object-cover border-2 border-theme-primary/20 grow-0 shrink-0 inline-flex items-center justify-center">
            <User width={35} height={35} color="none" outline="#bbbbbb" />
          </div>
        )}
        <div className="flex flex-col gap-1">
          <h1 className="text-2xl text-theme-black mb-0 line-clamp-1">
            {guest?.name}
          </h1>
        </div>
      </div>
      <div className="overflow-y-auto px-4 py-12 gap-6 flex flex-col items-center h-full">
        <div className="flex flex-col items-center gap-4 grow flex-1 w-full">
          <OptionCard
            icon={menuIcons.recorder}
            title={intl.formatMessage({
              id: "button.options.record",
              defaultMessage: "Gravar agora",
            })}
            description={intl.formatMessage({
              id: "message.options.record",
              defaultMessage: "Grave agora sua mensagem especial!",
            })}
            onClick={handleRecordClick}
          />

          <OptionCard
            icon={menuIcons.uploader}
            title={intl.formatMessage({
              id: "button.options.upload",
              defaultMessage: "Escolher arquivos",
            })}
            description={intl.formatMessage({
              id: "message.options.gallery",
              defaultMessage:
                "Compartilhe as melhores fotos e vídeos da festa!",
            })}
            onClick={handleUploadClick}
          />

          {event?.publicGallery && (
            <OptionCard
              icon={menuIcons.gallery}
              title={intl.formatMessage({
                id: "button.options.gallery",
                defaultMessage: "Ver álbum",
              })}
              description={intl.formatMessage({
                id: "message.options.gallery",
                defaultMessage: "Veja as fotos e vídeos do evento!",
              })}
              onClick={handleGalleryClick}
            />
          )}

          {bingoGameEnabled && (
            <OptionCard
              icon={menuIcons.bingo}
              title={intl.formatMessage({
                id: "button.options.bingoGame",
                defaultMessage: "Bingo",
              })}
              description={intl.formatMessage({
                id: "message.options.bingoGame",
                defaultMessage: "Jogue o bingo da festa!",
              })}
              onClick={handleBingoGameClick}
            />
          )}

          {photoChallengeGameEnabled && (
            <OptionCard
              icon={menuIcons.photo_challenge}
              title={intl.formatMessage({
                id: "button.options.photoChallengeGame",
                defaultMessage: "Desafio de fotos",
              })}
              description={intl.formatMessage({
                id: "message.options.photoChallengeGame",
                defaultMessage: "Participe do desafio de fotos!",
              })}
              onClick={handlePhotoChallengeGameClick}
            />
          )}

          {quizGameEnabled && (
            <OptionCard
              icon={menuIcons.quiz}
              title={intl.formatMessage({
                id: "button.options.quizGame",
                defaultMessage: "Quiz",
              })}
              description={intl.formatMessage({
                id: "message.options.quizGame",
                defaultMessage: "Teste seus conhecimentos!",
              })}
              onClick={handleQuizGameClick}
            />
          )}

          {teamMatchEnabled && (
            <OptionCard
              icon={menuIcons.team_match}
              title={intl.formatMessage({
                id: "button.options.teamMatchGame",
                defaultMessage: "Chapéu Seletor",
              })}
              description={intl.formatMessage({
                id: "message.options.teamMatchGame",
                defaultMessage: "De qual time você faz parte?",
              })}
              onClick={handleTeamMatchClick}
            />
          )}

          {foodMenuEnabled && (
            <OptionCard
              icon={menuIcons.food_menu}
              title={intl.formatMessage({
                id: "button.options.foodMenu",
                defaultMessage: "Cardápio",
              })}
              description={intl.formatMessage({
                id: "message.options.foodMenu",
                defaultMessage: "Veja o cardápio da festa!",
              })}
              onClick={handleFoodMenuClick}
            />
          )}

          {scheduleEnabled && (
            <OptionCard
              icon={menuIcons.schedule}
              title={intl.formatMessage({
                id: "button.options.schedule",
                defaultMessage: "Programação",
              })}
              description={intl.formatMessage({
                id: "message.options.schedule",
                defaultMessage: "Confira a programação da festa!",
              })}
              onClick={handleScheduleClick}
            />
          )}
        </div>

        <div className="w-full pt-6 flex justify-start justify-self-end">
          <div>
            <ChangeLanguageButton />
          </div>

          <div className="pl-2">
            <ShareButton onShare={onShareEventLink} />
          </div>

          <div className="pl-2">
            <LandingPageButton onClick={onLandingPageClick} />
          </div>
        </div>
      </div>
    </div>
  );
};

interface OptionCardProps {
  icon: string;
  title: string;
  description: string;
  onClick: () => void;
}

const OptionCard = ({ icon, title, description, onClick }: OptionCardProps) => {
  const { theme } = useEventStore();

  return (
    <button
      type="button"
      className={`w-full flex items-center px-2 py-5 gap-2 card ${theme.card} transition-transform duration-200 shadow-md hover:shadow-lg`}
      onClick={onClick}
    >
      <img src={icon} alt={title} className="w-20 transition-transform" />
      <div className="flex flex-col items-center gap-1 grow">
        <h3 className="text-lg font-semibold text-theme-black text-center">
          {title}
        </h3>
        <p className="text-theme-black text-sm w-48">{description}</p>
      </div>
    </button>
  );
};

const LegacyOptions = () => {
  const { event, theme, publicUrl } = useEventStore();
  const { logShareButton } = useLogEvent();
  const intl = useIntl();
  const navigate = useNavigate();

  const menuIcons = useMemo(
    () => (theme.name === "rose" ? RED_ICONS : BLACK_ICONS),
    [theme]
  );

  const handleRecordClick = () => navigate("/record/guide");

  const handleUploadClick = () => navigate("/upload");

  const handleGalleryClick = () => {
    const eventGalleryLink = `https://app.capsuladotempo.com/public/${event?.code}/gallery`;
    window.open(eventGalleryLink, "_blank");
  };

  const onShareEventLink = useCallback(() => {
    const text = intl.formatMessage({
      id: "message.shareLink",
      defaultMessage: "Compartilhe seus momentos favoritos da festa!",
    });
    shareLink(publicUrl, text, logShareButton);
  }, [intl, publicUrl, logShareButton]);

  const onLandingPageClick = useCallback(() => {
    const capsulaLadingPage = "https://videorecados.capsuladotempo.com";
    window.open(capsulaLadingPage, "_blank");
  }, []);

  return (
    <div className="template-container px-10 py-16 flex flex-col items-center">
      <div
        className={`flex flex-col items-center py-5 px-10 mb-8 gap-2 card ${theme.card}`}
      >
        <img src={menuIcons.recorder} alt="Gravar" className="w-32" />

        <p className="text-theme-black mt-3 w-48">
          <FormattedMessage
            id="message.options.record"
            defaultMessage="Grave agora sua mensagem especial!"
          />
        </p>

        <button
          type="button"
          className={`
              font-century-gothic-bold text-white py-3 w-[200px] flex whitespace-nowrap justify-center items-center rounded-xl ${theme.button}
            `}
          onClick={handleRecordClick}
        >
          <FormattedMessage
            id="button.options.record"
            defaultMessage="Gravar agora"
          />
        </button>
      </div>

      <div
        className={`flex flex-col items-center py-5 px-10 mb-8 gap-2 card ${theme.card}`}
      >
        <img src={menuIcons.uploader} alt="Upload" className="w-32" />

        <p className="text-theme-black mt-3 w-48">
          <FormattedMessage
            id="message.options.upload"
            defaultMessage="Compartilhe as melhores fotos e vídeos da festa!"
          />
        </p>

        <button
          type="button"
          className={`
              font-century-gothic-bold text-white py-3 w-[200px] flex whitespace-nowrap justify-center items-center rounded-xl ${theme.button}
            `}
          onClick={handleUploadClick}
        >
          <FormattedMessage
            id="button.options.upload"
            defaultMessage="Escolher arquivos"
          />
        </button>
      </div>

      {event?.publicGallery && (
        <div
          className={`flex flex-col items-center py-5 px-10 mb-8 gap-2 card ${theme.card}`}
        >
          <img src={menuIcons.gallery} alt="Upload" className="w-32" />

          <p className="text-theme-black mt-3 w-48">
            <FormattedMessage
              id="message.options.gallery"
              defaultMessage="Veja as fotos e vídeos do evento!"
            />
          </p>

          <button
            type="button"
            className={`
              font-century-gothic-bold text-white py-3 w-[200px] flex whitespace-nowrap justify-center items-center rounded-xl ${theme.button}
            `}
            onClick={handleGalleryClick}
          >
            <FormattedMessage
              id="button.options.gallery"
              defaultMessage="Ver álbum"
            />
          </button>
        </div>
      )}

      <div className="w-full h-full pt-6 px-4 flex justify-start">
        <div>
          <ChangeLanguageButton />
        </div>

        <div className="pl-2">
          <ShareButton onShare={onShareEventLink} />
        </div>

        <div className="pl-2">
          <LandingPageButton onClick={onLandingPageClick} />
        </div>
      </div>
    </div>
  );
};
