import { create } from "zustand";
import { TEventStore } from "./Store.types";
import { persist, createJSONStorage } from "zustand/middleware";
import { TEvent } from "services/types";

export const useEventStore = create<TEventStore>()(
  persist(
    (set) => ({
      event: null,
      theme: {
        name: "rose",
        logo: "#F76C6F",
        background: "bg-theme-rose",
        card: "rounded",
        button: "rounded-rose",
        buttonSecondary: "light-rose",
        loader: "#F76C6F",
        progressBar: "#F76C6F",
        input: "input-rose",
        box: "bg-[#F76C6F] text-white border border-[#F76C6F]",
        boxSecondary: "bg-white text-[#F76C6F] border border-[#F76C6F]",
      },
      language: "en-US",
      publicUrl: "",
      setEvent: (event) => {
        if (!event) return;
        const theme = getTheme(event);
        const language = getNavigatorLanguage();
        const publicUrl = `https://evento.capsuladotempo.com/?code=${event.code}`;

        set({ event, theme, language, publicUrl });
      },
      setLanguage: (language) => set({ language }),
    }),
    {
      name: "event-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

const getTheme = (event: TEvent) => {
  const name = event?.customization?.theme?.name;
  switch (name) {
    case "rose":
      return {
        name: "rose",
        logo: "#F76C6F",
        background: "bg-theme-rose",
        card: "rounded",
        button: "rounded-rose",
        buttonSecondary: "light-rose",
        loader: "#F76C6F",
        progressBar: "#F76C6F",
        input: "input-rose",
        box: "bg-[#F76C6F] text-white border border-[#F76C6F]",
        boxSecondary: "bg-white text-[#F76C6F] border border-[#F76C6F]",
      };
    case "white":
      return {
        name: "white",
        logo: "#272727",
        background: "bg-theme-white",
        card: "square",
        button: "square-white",
        buttonSecondary: "square-gray",
        loader: "#272727",
        progressBar: "#272727",
        input: "input-white",
        box: "bg-white text-[#272727] border border-[#272727]",
        boxSecondary: "bg-[#272727] text-white border border-[#272727]",
      };
    default:
      return {
        name: "rose",
        logo: "#F76C6F",
        background: "bg-theme-rose",
        card: "rounded",
        button: "rounded-rose",
        buttonSecondary: "light-rose",
        loader: "#F76C6F",
        progressBar: "#F76C6F",
        input: "input-rose",
        box: "bg-[#F76C6F] text-white border border-[#F76C6F]",
        boxSecondary: "bg-white text-[#F76C6F] border border-[#F76C6F]",
      };
  }
};

const getNavigatorLanguage = () => {
  const navigatorLanguage = navigator.language;
  const language = navigatorLanguage.split("-")[0];

  switch (language) {
    case "pt":
      return "pt-BR";
    case "es":
      return "es-ES";
    case "vi":
      return "vi-VN";
    default:
      return "en-US";
  }
};
