import PhotoTaker from "components/PhotoTaker/PhotoTaker";
import { useCallback, useMemo, useRef, useState } from "react";
import { useEventStore, useGuestStore } from "store";
import * as SpacesService from "libs/digitalocean/SpacesService";
import { TGuest } from "services/types";
import { useNavigate } from "react-router-dom";
import CdtAppPublicApi from "cdt-app-public-api/dist";
import { FormattedMessage, useIntl } from "react-intl";
import { base64ToFile } from "utils/utils";

// TODO 5 - Translate to all supported languages
export default function GuestForm() {
  const { event, theme } = useEventStore();
  const { setGuest } = useGuestStore();
  const navigate = useNavigate();
  const intl = useIntl();
  const nameRef = useRef<HTMLInputElement>(null);
  const [photoUploadStatus, setPhotoUploadStatus] = useState<
    "idle" | "uploading" | "success"
  >("idle");
  const [error, setError] = useState("");

  const photoStoragePath = useMemo(() => {
    const randomId = Math.random().toString(36).substring(7);
    return `guests/${event?.id}/${randomId}.jpg`;
  }, [event]);

  const handlePhotoTaken = useCallback(
    async (photoBase64: string) => {
      const file = base64ToFile(photoBase64, "photo.jpg", "image/jpeg");

      setPhotoUploadStatus("uploading");

      await SpacesService.uploadFile(file, photoStoragePath, (stats: any) =>
        console.log("uploading", { stats })
      );

      setPhotoUploadStatus("success");
    },
    [photoStoragePath]
  );

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      setError("");

      if (!nameRef.current?.value) {
        const message = intl.formatMessage({
          id: "message.guest.nameRequired",
          defaultMessage: "Nome é obrigatório",
        });
        setError(message);
        return;
      }

      let guest: TGuest = {
        eventId: event?.id as string,
        eventCode: event?.code as string,
        name: nameRef.current.value,
      };

      if (photoUploadStatus === "success") {
        guest = {
          ...guest,
          photoStoragePath,
        };
      }

      const cdtAppClient = new CdtAppPublicApi();
      const guestCreated = await cdtAppClient.createGuest(guest);

      setGuest(guestCreated as TGuest);

      navigate("/options");
    },
    [
      event?.code,
      event?.id,
      navigate,
      photoStoragePath,
      photoUploadStatus,
      setGuest,
      intl,
    ]
  );

  return (
    <div className="template-container px-10 py-16 flex flex-col items-center">
      <h1>
        <FormattedMessage
          id="title.guestForm"
          defaultMessage="Informe seu nome"
        />
      </h1>
      <form className="flex flex-col gap-4 w-full">
        <input
          type="text"
          name="name"
          placeholder={intl.formatMessage({
            id: "placeholder.guestForm.name",
            defaultMessage: "Seu nome ou apelido",
          })}
          className={`w-full px-4 py-3 ${theme.input} flex items-center justify-between`}
          ref={nameRef}
          required
        />
        {error && <p className="text-red-500">{error}</p>}
        <PhotoTaker
          canSwitchCamera={true}
          facingMode="user"
          startButtonClassNames={theme.buttonSecondary}
          width={500}
          height={500}
          onPhotoTaken={handlePhotoTaken}
        >
          <FormattedMessage
            id="button.guestForm.takePhoto"
            defaultMessage="Tirar foto"
          />
        </PhotoTaker>

        <button
          type="submit"
          className={`w-full px-4 py-3 ${theme.button} flex items-center justify-between`}
          onClick={handleSubmit}
          disabled={photoUploadStatus === "uploading"}
        >
          <span className="w-full text-center text-lg no-select">
            <FormattedMessage
              id="button.guestForm.submit"
              defaultMessage="Salvar"
            />
          </span>
        </button>
      </form>
    </div>
  );
}
